



































import { Mixins, Component } from "vue-property-decorator";
import { TransferRequestEditorControlProperties } from "./TransferRequestEditorControlView.vue";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import InfoViewMixin from "@/components/shared/InfoViewMixin";
import EventManager from "@/utilities/EventManager";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import TransferRequestService from "@/services/TransferRequestService";
import CompanyService from '@/services/CompanyService';
import DateUtil from '@/utilities/DateUtil';
import ResponseUtil from '@/utilities/ResponseUtil';

const TransferRequestEditorControlView = () => import("./TransferRequestEditorControlView.vue");
const AlertDialogView = () => import("@/components/shared/AlertDialogView.vue");
const TransferRequestDetailView = () => import("./TransferRequestDetailView.vue");
const TransferRequestSummaryView = () => import("./TransferRequestSummaryView.vue");

class TransferRequestInfoProperties {
    request: any = { requestBy: {}, transferTo: {} };
    currency: any = {};
    details: any[] = [];
    dateTimeFormat: string = null;
    events = new EventManager();
}

export { TransferRequestInfoProperties };

@Component({
	metaInfo: {
		link: [{ rel: "stylesheet", href: "/home-transfer-info.css" }]
	},
    components: {
        TransferRequestEditorControlView,
        AlertDialogView,
        TransferRequestDetailView,
        TransferRequestSummaryView,
    },
    data: () => ({
        saving: false,
    }),
})
export default class TransferRequestInfoView extends Mixins(InfoViewMixin) {
    private properties = new TransferRequestInfoProperties();
    private editorControl = new TransferRequestEditorControlProperties();
    private alertDialog = new AlertDialogProperties();
    private companyService = new CompanyService();
    private transferRequestService = new TransferRequestService();

	public get title() {
		const tmp1 = this.$t('title.transfer-request');
		const tmp2 = this.$t('title.create');
		const tmp3 = this.$t('title.modify');

		return `${tmp1} - ${this.isCreate() ? tmp2 : tmp3}`;
	}

    public get request() {
        return this.properties.request;
    }

    public created() {
        this.mode = this.$route.params.mode;

        if (!this.checkMode() || this.isCreate() || this.isView()) {
            AlertDialogProperties.error(this.alertDialog, null, this.$t("message.invalid-mode"));
            this.alertDialog.tag = { command: "invalid-mode" };
            this.alertDialog.visible = true;
        } else {
            this.load();
        }
    }

    public refreshEditorControl() {
        if (this.request.status === 'Processing') {
            this.editorControl.approveEnabled = true;
            this.editorControl.cancelEnabled = true;
        } else {
            this.editorControl.approveEnabled = false;
            this.editorControl.cancelEnabled = false;
        }
    }

    public async loadCurrency() {
        const r = await this.companyService.currency();
        this.properties.currency = r.data;
    }

    public async loadRequest() {
        var id = this.$route.query.id;
        const r = await this.transferRequestService.get({
            id,
            loadRequestBy: true,
			loadRequestByFacebookAccount: true,
            loadTransferTo: true,
			loadTransferToFacebookAccount: true
        });
        if (r.data.transferRequests.length === 0) {
            throw new Error(this.$t("message.record-not-found").toString());
        }
        this.properties.dateTimeFormat = ResponseUtil.getDateTimeFormat(r);
        var request = r.data.transferRequests[0];
        this.properties.request = request;
        this.refreshEditorControl();
        await this.properties.events.fire("load-details");
    }

    public async load() {
        try {
            await this.loadCurrency();
            await this.loadRequest();
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public async approve() {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        try {
            await this.transferRequestService.approve(this.request);
            this.load();
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public async cancel() {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        try {
            await this.transferRequestService.cancel(this.request);
            this.load();
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public async control(button: string) {
        if (button === "approve") {
            this.approveDialog();
        } else if (button === "cancel") {
            this.cancelDialog();
        }
    }

    public approveDialog() {
        const t = this.$t("title.approve-transfer");
        const m = this.$t("message.approve-transfer");
        AlertDialogProperties.standard(this.alertDialog, t, m);
        this.alertDialog.buttons = [this.$t("text.yes"), this.$t("text.no")];
        this.alertDialog.primaryButton = this.$t("text.yes");
        this.alertDialog.tag = { command: "approve" };
        this.alertDialog.visible = true;
    }

    public cancelDialog() {
        const t = this.$t("title.cancel-transfer");
        const m = this.$t("message.cancel-transfer");
        AlertDialogProperties.standard(this.alertDialog, t, m);
        this.alertDialog.buttons = [this.$t("text.yes"), this.$t("text.no")];
        this.alertDialog.primaryButton = this.$t("text.yes");
        this.alertDialog.tag = { command: "cancel" };
        this.alertDialog.visible = true;
    }

    public async alertDialogClicked(button: string) {
        const tag = this.alertDialog.tag;
        this.alertDialog.visible = false;
        if (tag) {
            if (tag.command === "approve" && button === this.$t("text.yes")) {
                await this.approve();
            } else if (tag.command === "cancel" && button === this.$t("text.yes")) {
                await this.cancel();
            }
        }
        await this.alertDialogClosed();
    }

    public async alertDialogClosed() {
        if (this.alertDialog.visible) {
            return;
        }

        const tag = this.alertDialog.tag;
        if (tag && (tag.command === "invalid-mode" || tag.command === "load-error" || tag.command === "record-deleted")) {
            await this.$router.push("/management/customer/transfer-requests");
        }
    }
}
