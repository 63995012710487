



































import { Vue, Component, Prop } from "vue-property-decorator";

class TransferRequestEditorControlProperties {
    approveEnabled: boolean = true;
    cancelEnabled: boolean = true;
}

export { TransferRequestEditorControlProperties };

@Component({})
export default class TransferRequestEditorControlView extends Vue {
    @Prop({ default: new TransferRequestEditorControlProperties() })
    private properties: TransferRequestEditorControlProperties;

    private clicked(button: string) {
        this.$emit("clicked", button);
    }
}
